import './Trip.scss'
import Button from '../../components/Button/Button';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
// icons
import calendar from '../../assets/icons/calendar1.png'
import pin from '../../assets/icons/location.png'
import usd from '../../assets/icons/usd1.png'
// import check from '../../assets/icons/check.png'
// import ex from '../../assets/icons/x.png'
import suitcase from '../../assets/icons/suitcase.png'
import incense from '../../assets/icons/incense.png'
import stall from '../../assets/icons/stall.png'
import plane from '../../assets/icons/plane.png'
import palace from '../../assets/icons/palace.png'
import dumpling from '../../assets/icons/dumpling.png'
import massage from '../../assets/icons/massage.png'


const Trip = () => {

    return (
        <main className='trip'>
            <header className='trip__header'>
                <div className='trip__header-wrapper'>
                    <h6 className='trip__header-date'>Sept 12, 2024 - Sept 18, 2024</h6>
                    <h1 className='trip__header-title'>Beijing Bound</h1>
                    <h2 className='trip__header-text'>A Week Long Journey Around China's Crown</h2>
                </div>
            </header>

            <section className='trip__main' >
                {/* <section className='trip__main-sec'>
                    <div className='trip__main-sec-left'>
                        <h3 className='trip__main-sec-title'>About this trip...</h3>
                        <p className='trip__main-sec-text'>Join us for a week-long adventure in Beijing, China, where we'll dive deep into its culture and cuisine.</p>
                        <p className='trip__main-sec-text'>
                            I want to ensure that everyone experiences China in its entirety, not just as an outsider visiting.
                        </p>
                        <p className='trip__main-sec-text'> And that begins with tasting authentic Chinese flavors and learn to cook traditional dishes, going beyond the familiar Orange Chicken from your favorite Chinese spots.</p>
                    </div>

                    <div className='trip__main-sec-right'>
                        <div className='trip__main-sec-img1'></div>
                        <div className='trip__main-sec-img2'></div>
                        <div className='trip__main-sec-img3'></div>
                    </div>
                </section> */}

                <section className='trip__main-sec'>
                    <div className='trip__main-sec-img'></div>

                    <div className='trip__main-sec-left'>
                        {/* {/* <h3 className='trip__main-sec-title'>During our trip...</h3> */}
                        <h3 className='trip__main-sec-title'>About this trip...</h3>
                        <p className='trip__main-sec-text'>You'll  immerse yourself in Beijing's rich history and culture, as well as experiencing its vibrant local nightlife. </p>
                        <p className='trip__main-sec-text'>And let's not forget the iconic Great Wall—one of the world's greatest wonders.</p>
                        <p className='trip__main-sec-text'>Join us for a remarkable toboggan ride down the wall, all while savoring what Beijing has to offer.
                        </p>
                        {/* <p className='trip-sec-text'></p> */}
                    </div>
                </section>

                {/* start of trip__detail of trip */}
                <section className='trip__detail'>
                    <h3 className='trip__detail-title trip__detail-title2'>Trip Details</h3>
                    <div className='trip__detail-wrapper'>
                        <div className='trip__detail-left'>
                            <h3 className='trip__detail-title'>Trip Details</h3>

                            <div className='trip__detail-date trip__detail-block'>
                                <span className='trip__detail-icon'><img className="trip__detail-icon-img" src={calendar} alt='calendar icon' /></span>
                                <h5 className='trip__detail-info'>Sept 12, 2024 - Sept 18, 2024</h5>
                            </div>

                            <div className='trip__detail-location trip__detail-block'>
                                <span className='trip__detail-icon'><img className="trip__detail-icon-img" src={pin} alt='location icon' /></span>
                                <h5 className='trip__detail-info'>Beijing, China</h5>
                            </div>

                            <div className='trip__detail-block'>
                                {/* <span className='trip__detail-icon'><img className="trip__detail-icon-img" src={check} alt='check icon' /></span> */}
                                <ul className='trip__detail-info trip__detail-info-list'>
                                    <h3 className='trip__detail-subtitle' >What's included:</h3>
                                    <li className='trip__detail-item'>Private English guide </li>
                                    <li className='trip__detail-item'>Transportation & Sight tickets</li>
                                    <li className='trip__detail-item'>4 star Boutique hotel</li>
                                    <li className='trip__detail-item'>Cultural activities</li>
                                    <li className='trip__detail-item'>Welcome & Farewell dinners</li>
                                    <li className='trip__detail-item'>Yours truly for any trip assistance</li>
                                </ul>
                            </div>

                            <div className='trip__detail-block'>
                                {/* <span className='trip__detail-icon'><img className="trip__detail-icon-img" src={ex} alt='x icon' /></span> */}
                                <ul className='trip__detail-info trip__detail-info-list'>
                                    <h3 className='trip__detail-subtitle ' >What's not included:</h3>
                                    <li className='trip__detail-item'>Flight tickets</li>
                                    <li className='trip__detail-item'>Visa fee: Any visa related questions please visit <HashLink className='trip__detail__link' smooth to={'/faq#deposit-info'}>FAQ</HashLink></li>
                                    <li className='trip__detail-item'>Personal expenses</li>
                                </ul>
                            </div>

                            <div className='trip__detail-block'>
                                <span className='trip__detail-icon'><img className="trip__detail-icon-img" src={usd} alt='dollar icon' /></span>
                                <h5 className='trip__detail-info'>Join our tour today with below two payment options:</h5>
                            </div>

                            <div className='trip__detail-price'>
                                <div className='trip__detail-price-block'>
                                    <h5 className='trip__detail-info trip__detail-price-info'>Pay in full: $1099 Per person based on double occupancy</h5>
                                    <Link className="trip__detail-price-link" to="https://square.link/u/vrA9hLvB">
                                        <Button name={'trip__detail-btn trip__detail-price-btn'} value={'book'} />
                                    </Link>
                                </div>

                                <div className='trip__detail-price-block'>
                                    <h5 className='trip__detail-info trip__detail-price-info'>Reserve your spot now with a $250 deposit fee <HashLink className='trip__detail__link' smooth to={'/faq#deposit-info'}>*Learn More*</HashLink></h5>
                                    <Link className="trip__detail-price-link" to="https://square.link/u/DQIEI4jV">
                                        <Button name={'trip__detail-btn trip__detail-price-btn'} value={'book'} />
                                    </Link>

                                </div>
                            </div>

                            <div className='trip__detail-block trip__detail-block-btns'>
                                <h5 className='trip__detail-info'>* If you have any questions, please don't hesitate to <HashLink className='trip__detail__link' smooth to={'/contact#contact'}>contact us</HashLink>*</h5>
                            </div>
                        </div>
                        <div className='trip__detail-right'></div>
                    </div>
                </section>
            </section>

            {/* start of itinerary */}
            <section className='itinerary' id='trip-itinerary'>

                <h3 className='itinerary__caption'>Itinerary</h3>
                <div className='itinerary__caption-underline'></div>
                <div className='itinerary__wrapper itinerary__wrapper-left'>
                    <img className="itinerary__icon itinerary__icon-1 itinerary__icon-left" src={suitcase} alt='suitcase icon' />
                    <div className='itinerary__block'>
                        <h6 className='itinerary__subtitle'>Day 1: Sept 12, 2024</h6>
                        <h5 className='itinerary__title'>Arrival & Rest</h5>
                        <ul className='itinerary__list'>
                            <li className="itinerary__item">Airport pickup</li>
                            <li className="itinerary__item">Down time to relax or explore the nearby night market</li>
                        </ul>
                    </div>
                    <div className="itinerary__img-1 itinerary__img"></div>
                </div>

                <div className='itinerary__wrapper itinerary__wrapper-right'>
                    <img className="itinerary__icon itinerary__icon-2 itinerary__icon-right" src={palace} alt='suitcase icon' />
                    <div className="itinerary__img itinerary__img-2"></div>
                    <div className='itinerary__block itinerary__block-right'>
                        <h6 className='itinerary__subtitle'>Day 2: Sept 13, 2024</h6>
                        <h5 className='itinerary__title'>Summer Palace & Old Hutongs</h5>
                        <ul className='itinerary__list'>
                            <li className="itinerary__item">Explore the largest and best-preserved imperial garden, aka the Qing dynasty emperors' resort</li>
                            <li className="itinerary__item">Stroll around Houhai Lake and wander the 'hutongs,' traditional Beijing alleys</li>
                            <li className="itinerary__item">Welcome dinner featuring the traditional Peking Duck</li>
                        </ul>
                    </div>
                </div>

                <div className='itinerary__wrapper itinerary__wrapper-left'>
                    <img className="itinerary__icon itinerary__icon-3 itinerary__icon-left" src={incense} alt='suitcase icon' />
                    <div className='itinerary__block'>
                        <h6 className='itinerary__subtitle'>Day 3: Sept 14, 2024</h6>
                        <h5 className='itinerary__title'>Forbidden City, JingShan Park & Lama Temple</h5>
                        <ul className='itinerary__list'>
                            <li className="itinerary__item">Visit Tian'anmen Sq & Forbidden City(imperial palace), where the old China meets new China</li>
                            <li className="itinerary__item">Climb Jinshan Park for a bird's-eye view of the Forbidden City</li>
                            <li className="itinerary__item">Experience the largest Tibetan Buddhist temple in Beijing</li>
                        </ul>
                    </div>
                    <div className="itinerary__img-3 itinerary__img"></div>
                </div>

                <div className='itinerary__wrapper itinerary__wrapper-right'>
                    <img className="itinerary__icon itinerary__icon-4 itinerary__icon-right" src={dumpling} alt='dumpling icon' />
                    <div className="itinerary__img itinerary__img-4"></div>
                    <div className='itinerary__block itinerary__block-right'>
                        <h6 className='itinerary__subtitle'>Day 4: Sept 15, 2024</h6>
                        <h5 className='itinerary__title'>Great Wall of China & Private cooking class</h5>
                        <ul className='itinerary__list'>
                            <li className="itinerary__item">Climb and tobaggan down one of the 7 Modern Wonders of the World</li>
                            <li className="itinerary__item">Master the art of dumpling-making and get ready to cook up your new favorite traditional Chinese dishes</li>
                        </ul>
                    </div>
                </div>

                <div className='itinerary__wrapper itinerary__wrapper-left'>
                    <img className="itinerary__icon itinerary__icon-5" src={stall} alt='stall icon' />
                    <div className='itinerary__block'>
                        <h6 className='itinerary__subtitle'>Day 5: Sept 16, 2024</h6>
                        <h5 className='itinerary__title'>798 Art District, Sanlitun, Wangfujing night market</h5>
                        <ul className='itinerary__list'>
                            <li className="itinerary__item">Stroll through 798 Art District: a vibrant hub of contemporary creativity and expression</li>
                            <li className="itinerary__item">Sample some street snacks at Wangfujing Night Market and then fully dive into the vibrant Sanlitun nightlife</li>
                        </ul>
                    </div>
                    <div className="itinerary__img-5 itinerary__img"></div>
                </div>

                <div className='itinerary__wrapper itinerary__wrapper-right'>
                    <img className="itinerary__icon itinerary__icon-6 itinerary__icon-right" src={massage} alt='massage icon' />
                    <div className="itinerary__img itinerary__img-6"></div>
                    <div className='itinerary__block itinerary__block-right'>
                        <h6 className='itinerary__subtitle'>Day 6: Sept 17, 2024</h6>
                        <h5 className='itinerary__title'>Temple of Heaven, Shopping & Downtime </h5>
                        <ul className='itinerary__list'>
                            <li className="itinerary__item">Roam the Temple of Heaven, a place for rituals and sacrifices</li>
                            <li className="itinerary__item">The rest of the day is yours to spend as you wish, whether it's shopping for souvenirs or indulging in any self-care routine—I'm happy to assist with any planning</li>
                            <li className="itinerary__item">Farewell dinner</li>

                        </ul>
                    </div>
                </div>

                <div className='itinerary__wrapper itinerary__wrapper-left'>
                    <img className="itinerary__icon itinerary__icon-7" src={plane} alt='plane icon' />
                    <div className='itinerary__block'>
                        <h6 className='itinerary__subtitle'>Day 7: Sept 18, 2024</h6>
                        <h5 className='itinerary__title'>Departure Day</h5>
                        <ul className='itinerary__list'>
                            <li className="itinerary__item">Airport transfer for return flight</li>
                        </ul>
                    </div>
                    <div className="itinerary__img-7 itinerary__img"></div>
                </div>


            </section>

            <section className='important'></section>
        </main >
    )
}

export default Trip