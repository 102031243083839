import './Input.scss'


const Input = ({ name, type, placeholder, value, onchange, checked }) => {

    if (type === 'radio') {
        return (
            <input
                className='input__radio'
                name={name}
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={onchange}
                checked={checked}
                required
            />
        )
    } else {
        return (
            <input
                className='input'
                name={name}
                type={type}
                value={value}
                placeholder={placeholder}
                onChange={onchange}
                checked={checked}
                required
            />
        )
    }


}

export default Input