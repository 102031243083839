import './NextBtn.scss'

const NextBtn = ({ className, onClick, icon: Icon }) => {
    return (
        <div className={className} onClick={onClick}>
            <Icon className="arrow" style={{ fontSize: "55px" }} />
        </div>
    );
};

export default NextBtn;