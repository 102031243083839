import { useNavigate, Link } from 'react-router-dom'
import './Footer.scss'
import Button from '../Button/Button'
import fb from '../../assets/icons/fb.png'
import ig from '../../assets/icons/ig.png'

const Footer = () => {
    const navigate = useNavigate()

    const pageJump = (value) => {
        navigate(value === '' ? '/' : `/${value.toLowerCase()}`);
        window.scrollTo(0, 0)
    }

    return (
        <main className='footer'>
            <Button name={'nav__btn-logo footer__logo'} value={'TraveLinLin'} />
            <ul className='footer__links'>
                <li className='footer__item' onClick={() => pageJump('')}>Home</li>
                <li className='footer__item' onClick={() => pageJump('about')} >About</li>
                <li className='footer__item' onClick={() => pageJump('trip')} >Trip</li>
                <li className='footer__item' onClick={() => pageJump('faq')} >FAQ</li>
                <li className='footer__item' onClick={() => pageJump('contact')} >Contact</li>
                <li className='footer__item' onClick={() => pageJump('terms')} >Terms</li>
                <li className='footer__item' onClick={() => pageJump('privacy')} >Privacy</li>
            </ul>

            <div className='footer__socials'>
                <Link to={'https://www.instagram.com/travelin_lin'}>
                    <img className="footer__socials-icon" src={ig} alt='instagram icon' /></Link>

                <Link to={'https://www.facebook.com/travelinlin/'}>
                    <img className="footer__socials-icon" src={fb} alt='facebook icon' /></Link>

            </div>
        </main>
    )
}

export default Footer