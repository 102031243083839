// core stuff
import { BrowserRouter, Routes, Route } from "react-router-dom";


// components, pages, styles
import './styles/global.scss'
import Nav from './components/Navbar/Nav'
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Trip from "./pages/Trip/Trip";
import Contact from "./pages/Contact/Contact";
import Faq from './pages/Faq/Faq'
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import Footer from "./components/Footer/Footer";
import Privacy from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/Terms";
import Reviews from "./components/Reviews/Reviews";

function App() {

  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/trip' element={<Trip />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
        {/* test */}
        <Route path='/reviews' element={<Reviews />} />

        <Route path='*' element={<PageNotFound />} />

      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
