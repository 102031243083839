import { Link } from 'react-router-dom';
import './Faq.scss';

const Faq = () => {

    return (
        <main className='faq'>

            <header className='faq__header'>
                <div className='faq__header-wrapper'>
                    <h1 className='faq__header-title'>Got Questions?</h1>
                </div>
            </header>

            <section className='faq__main'>
                <h2 className='faq__main-title' id='deposit-info'>Frequently Asked Questions</h2>
                <ul className='faq__main-list'>
                    <h5 className='faq__main-subtitle'>Tour Related</h5>
                    <li className='faq__main-item'>
                        <h6 className='faq__main-question'>What is the purpose of paying a deposit for the tour, and is it refundable?</h6>
                        <p className='faq__main-answer'> Space on this tour is limited. Paying a deposit secures your spot, which is refundable up to one month before the trip starts on August 12th. Your deposit will be credited towards your final payment.</p>
                    </li>

                    <li className='faq__main-item'>
                        <h6 className='faq__main-question'> What information do I need to provide to join this trip?</h6>
                        <ul className='faq__main-answer'>
                            <li className='faq__main-answer-item'>Ensure your passport is valid.</li>
                            <li className='faq__main-answer-item'>Obtain your Chinese visa (feel free to reach out for any questions related to obtaining your visa).</li>
                            <li className='faq__main-answer-item'>Provide your flight information.</li>
                            <li className='faq__main-answer-item'>Ensure you are paid in full by September 1st.</li>
                        </ul>
                    </li>

                    <li className='faq__main-item'>
                        <h6 className='faq__main-question'>Which airport should I fly into and when should I arrive?</h6>
                        <p className='faq__main-answer'>Please plan ahead and arrive by the 12th. There are 2 international airports in Beijing, PEK (Beijing Capital international ariport) or PKX (Beijing Daxing International airport).</p>
                    </li>

                    <li className='faq__main-item'>
                        <h5 className='faq__main-subtitle'>Visa Related</h5>
                        <h6 className='faq__main-question'>Do I need a visa to visit China?</h6>
                        <p className='faq__main-answer'>As an US citizen, yes you do. </p>
                    </li>

                    <li className='faq__main-item'>
                        <h6 className='faq__main-question'>What type of visa do I need for my visit and how much is the cost? </h6>
                        <p className='faq__main-answer'>You will need to apply for the L visa for tourism which costs $140, best to apply for the 10-year multiple entry as it costs the same as single entry.</p>
                    </li>

                    <li className='faq__main-item'>
                        <h6 className='faq__main-question'>What documents do I need to prepare for the visa application?</h6>
                        <ul className='faq__main-answer'>
                            <li className='faq__main-answer-item'>Passport: Must be valid for more than 6 months and have at least 2 blank visa pages</li>
                            <li className='faq__main-answer-item'>Photocopy of the passport page</li>
                            <li className='faq__main-answer-item'>Printed copy of your entire online application form, you can fill out your online application <Link to='https://cova.mfa.gov.cn/qzCoCommonController.do?show&pageId=index&locale=en_US' className='faq__main-link'>here</Link></li>
                            <li className='faq__main-answer-item'>Driver's license or state ID and a copy of it</li>
                            <li className='faq__main-answer-item'>If you are not a US citizen, bring proof of residency and a copy of the document</li>
                            {/* <li className='faq__main-answer-item'></li> */}
                        </ul>
                    </li>

                    <li className='faq__main-item'>
                        <h6 className='faq__main-question'>How to apply and how long is the processing time?</h6>
                        <p className='faq__main-answer'>There are 5 major Chinese Consulates in the U.S.: New York, Chicago, San Francisco, Los Angeles, and Houston. Please refer to the official Chinese embassy website
                            <Link to="http://us.china-embassy.gov.cn/lsfw/qaxqbllsyw/202203/t20220315_10651716.htm" className='faq__main-link'> here </Link>
                            to find out which consulate covers your state's jurisdiction, as that is the only consulate you are allow to apply to. (You can use Google Translate as this page is in Chinese). </p>
                        <p className='faq__main-answer'>You can choose to apply in person or use an agency. When applying in person, processing time typically ranges from 3 to 5 days, while using an agency can take up to 6 weeks. Please plan ahead accordingly. If you choose to use an agency, while I have not personally used one, I have heard great reviews about Oasis and CIBT agency.</p>

                    </li>

                    <li className='faq__main-item'>
                        <h6 className='faq__main-question'>Tips for the Visa application</h6>
                        <ul className='faq__main-answer'>
                            <li className='faq__main-answer-item'>Please carefully review these two documents
                                (<Link to="http://us.china-embassy.gov.cn/eng/lsfw/zj/qz2021/202206/t20220614_10702581.htm#Annex4" className='faq__main-link'>here </Link>
                                and <Link to="http://us.china-embassy.gov.cn/eng/lsfw/zj/qz2021/202309/t20230919_11144908.htm" className='faq__main-link'>here</Link>)
                                provided on the Chinese embassy website to ease the application process.</li>
                            <li className='faq__main-answer-item'>Before visiting in person, make sure you have all the necessary documents for your application, as you will be turned away if any required documents are missing.</li>
                            <li className='faq__main-answer-item'>Verify that all information provided in your online application is accurate. For example, if your middle name appears on your legal document, ensure it is also included in your application.</li>
                            <li className='faq__main-answer-item'>Some consulates may require appointments for visa applications, so plan ahead accordingly.</li>
                        </ul>
                    </li>

                    <li className='faq__main-item'>
                        <h5 className='faq__main-subtitle'>General</h5>
                        <h6 className='faq__main-question'>What should I bring for this trip?</h6>
                        <ul className='faq__main-answer'>
                            <li className='faq__main-answer-item'><b> Sweaters & Light jackets: </b> September in Beijing will be similar to NYC. Temperatrues range from 40s ~ 60s dgrees, so be sure to bring some warm clothes.</li>
                            <li className='faq__main-answer-item'><b>Walking shoes:</b> Be sure to bring comfortable shoes that you can hike in. We'll be walking a lot exploring different sites.</li>
                            <li className='faq__main-answer-item'><b>Kleenex: </b>Public bathrooms in China typically do not supply toilet paper, so be sure to always Kleenex with you. They cost about $1 for a 2 pack in convenience stores in Beijing.</li>
                            <li className='faq__main-answer-item'><b>Medication: </b>Please bring your allergy and other prescription medications you normally take. I highly recommend bringing some preventative medicine for common cold, diarrhea and other sicknesses.</li>
                            <li className='faq__main-answer-item'><b>VPN:</b> Google and Meta products will not work in China without VPN. Please download a VPN app before leaving stateside if you wish to access those apps. Some free VPNs include OneClick, Ultrasurf, and Fair VPN, but free VPNs can be very spotty in China.</li>
                            <li className='faq__main-answer-item'><b>Contact: </b>Consider downloading WeChat. WeChat is a Chinese messaging app similar to WhatsApp (WhatsApp will only work while using VPN) that is commonly used in China. WeChat is the best easiest way to communicate with me other than text.</li>
                            <li className='faq__main-answer-item'><b>Translator app:</b> Highly recommend having a translator app on your phone. If you have WeChat, there is a scanning option that works like Google Lens. It will be able to scan and translate any images.</li>

                        </ul>
                    </li>

                    <li className='faq__main-item'>
                        <h6 className='faq__main-question'>Should I bring cash, and will I be able to use my credit cards?</h6>
                        <p className='faq__main-answer'>Cash payment, is the easiest for tourists in China. Some international malls and big brand stores will accept foreign credit cards. Tipping is not a norm in China. If you feel that the service was exceptional, you're welcome to leave a tip at your discretion.</p>
                        <p className='faq__main-answer'>Easiest way to get Chinese money (Yuan or RMB) is to use your ATM/debit card in China, this is the best way to get an excellent exchange rate.
                            Make sure you notify your bank that you'll be using your cards in China before leaving.</p>
                        <p className='faq__main-answer'><b>Cards:</b> Ensure you check the foreign transaction and international ATM withdrawal fee for your cards. <b>Tip:</b> Capital One 360 is the debit card I personally use for international trips.
                            It does not charge international transaction or ATM withdrawal fees (as long as the ATM itself does not incur a fee), and it is widely accepted in most Chinese banks.</p>
                        <p className='faq__main-answer'><b>Cash:</b> You can bring US dollars to exchange them in banks and hotels in China. Exchange rates may vary. On average, daily expenses, including meals, should cost around $60. (Exchange rate: $1 = ~7.25 Yuan)</p>

                    </li>

                    <li className='faq__main-item'>
                        <h6 className='faq__main-question'>Will I be able to use my phone in China?</h6>
                        <p className='faq__main-answer'>If you have T-Mobile, you are covered under their free international service. Please visit their site for details.</p>
                        <p className='faq__main-answer'>If you use other cell service company, please call and find out if they offer an international plan. Otherwise, our hotel and some public places offer free wifi.</p>
                        <p className='faq__main-answer'>Lastly, if you choose to purchase a local SIM card, ensure that your cell phone is unlocked and capable of accepting an international SIM card before departing from the United States.</p>
                    </li>


                    <li className='faq__main-item'>
                        <h6 className='faq__main-question'>Food Related Tips</h6>
                        <ul className='faq__main-answer'>
                            <li className="faq__main-answer-item"><b>Avoid drinking tap water at all times.</b></li>
                            <li className="faq__main-answer-item">It is safer to eat fruits with skin you can peel.</li>
                            <li className="faq__main-answer-item">If you are a vegetarian, you'll find plenty of options available. If Chinese cuisine isn't to your taste, there are also other international cuisines and well-known fast-food chains to explore. For instance, KFC is the largest fast-food chain in China. (<b>Tip:</b> Spicy wings in McDonalds are a must try). </li>
                            <li className="faq__main-answer-item">It's common to experience digestive issues when first arriving in China as your stomach adjusts to the local cuisine. Remember to bring preventative medication to alleviate any discomfort.</li>
                            {/* <li className="faq__main-answer-item"></li> */}
                        </ul>
                    </li>

                    <li className='faq__main-item'>
                        <h6 className='faq__main-question'>Transportation Tips</h6>
                        <p className='faq__main-answer'>We provide transportation to and from all major tour sites. However, if you decide to explore further during your down time, these are some options:</p>
                        <ul className='faq__main-answer'>
                            <li className="faq__main-answer-item"><b>Subway:</b> The subway system in Beijing is very easy to use. Each train has English announcements. Be sure to grab a map or download an offline version before heading out. Most lines operate between 5am - 11pm.</li>
                            <li className="faq__main-answer-item"><b>Taxi:</b> Most Chinese taxi drivers do not speak English, please have the address ready in Chinese to show to drivers. Be sure the drivers use the meter to prevent any scams.</li>
                            <li className="faq__main-answer-item">Always carry a copy of the hotel address in Chinese along with my contact information. Hotel business cards are readily available at the front desk upon arrival.</li>

                            {/* <li className="faq__main-answer-item"></li> */}

                        </ul>
                    </li>
                    <p className='faq__main-answer-contact'>* For any additional questions, feel free to contact me directly or fill out a contact form *</p>

                    {/* <li className='faq__main-item'>
                        <h6 className='faq__main-question'></h6>
                        <p className='faq__main-answer'></p>
                    </li> */}

                </ul>
            </section>
        </main>
    )
}

export default Faq