import { useNavigate } from 'react-router-dom'
import Button from '../../components/Button/Button'
import './PageNotFound.scss'

const PageNotFound = () => {
    const navigate = useNavigate()

    const goHome = () => {
        navigate('/')
    }

    const goBack = () => {
        window.history.back()
    }

    return (
        <main className='notfound'>
            <section className='notfound__wrapper'>
                <h1 className='notfound__title'>404</h1>
                <h2 className='notfound__subtitle'>Page Not Found</h2>
                <div className='notfound__btns'>
                    <Button value={'Home'} name='notfound__btn' btnfunc={goHome} />
                    <Button value={'Back'} name='notfound__btn' btnfunc={goBack} />
                </div>
            </section>

        </main>
    )
}

export default PageNotFound