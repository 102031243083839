import './Card.scss'
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';

const maxWords = 40;

function Card({ name, avatar, review }) {

    console.log(name)

    const [showMore, setShowMore] = useState(false)

    const toggleShowMore = () => {
        setShowMore((prevShowMore) => !prevShowMore)
    }

    const getTruncatedReview = (text, wordLimit) => {
        const words = text.split(' ');
        if (words.length <= wordLimit) {
            return text;
        }
        return words.slice(0, wordLimit).join(' ') + '...';
    };

    const truncatedReview = getTruncatedReview(review, maxWords);

    return (
        <div className="Card">
            <div className="Client-Card Card__container">
                <Avatar
                    className="Card__avatar"
                    src={avatar}
                    sx={{
                        width: { xs: 80, sm: 150 }, // xs for screens <600px, sm for screens >=600px
                        height: { xs: 80, sm: 150 },
                    }}
                />
                <p className="Card__review">
                    {showMore ? review : truncatedReview}
                    {review.split(' ').length > maxWords && (
                        <span onClick={toggleShowMore} className="Card__toggle">
                            {showMore ? 'Show Less' : 'Show More'}
                        </span>
                    )}
                </p>

                <p>
                    <span className="Name Card__name">
                        {name}
                    </span>
                </p>

            </div>
        </div>
    );
}

export default Card;
