import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-flip';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// images
import hotpot from '../../assets/images/hotpot.jpg'
import cooking from '../../assets/images/cooking1.JPG'
import dumpling from '../../assets/images/dumpling.jpeg'
import dumpling1 from '../../assets/images/dumpling2.jpeg'
import cucumber from '../../assets/images/soad_cucumber.JPG'
import wok from '../../assets/images/wok_barb.jpeg'
import taichi from '../../assets/images/taichi.jpg'
import taichi1 from '../../assets/images/taichi2.jpeg'
import taichi2 from '../../assets/images/taichi3.JPG'
import tea from '../../assets/images/tea_ceremony.JPG'
import tea1 from '../../assets/images/tea2.JPG'
// import tea1 from '../../assets/images/tea2.JPG'




import './Carousel.scss'

// import required modules
import { EffectFlip, Pagination, Navigation } from 'swiper/modules';


const Carousel = () => {
    return (
        <Swiper
            effect={'flip'}
            grabCursor={true}
            pagination={true}
            navigation={true}
            modules={[EffectFlip, Pagination, Navigation]}
            className="mySwiper"
        >
            <SwiperSlide>
                <img src={dumpling1} alt='dumpling making' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={cooking} alt='cooking class' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={taichi} alt='taichi in the park' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={tea} alt='tea ceremony' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={wok} alt='cooking in a wok' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={taichi1} alt='self defensetaichi lesson' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={tea1} alt='smelling tea' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={hotpot} alt='hotpot dinner' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={cucumber} alt='showing cucumber cutting result' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={dumpling} alt='dumpling making process' />
            </SwiperSlide>
            <SwiperSlide>
                <img src={taichi2} alt='taichi class in the park' />
            </SwiperSlide>

        </Swiper>
    )

}

export default Carousel