// other components, pages, style
import './Contact.scss'
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'
import dont from '../../assets/icons/error.png'
import letter from '../../assets/icons/letter.png'
// import close from '../../assets/icons/close.png'
import loading from '../../assets/icons/loading.png'
import failed from '../../assets/icons/failed.png'

// core stuff 
import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import HCaptcha from '@hcaptcha/react-hcaptcha'

const Contact = () => {
    // set, handle and validate phone num
    const [phoneNum, setPhoneNum] = useState('')

    const [validNum, setValidNum] = useState(true)

    const phoneHandle = (e) => {
        if (e === undefined) {
            setValidNum(false)
        } else {
            setValidNum(true)
            setPhoneNum(e)
        }

    }
    // captcha state and token func
    const [hCaptchaKey, setHCaptchaKey] = useState(0)

    const [hCaptchaResponse, setHCaptchaResponse] = useState('')

    const onHCaptchaChange = (token) => {
        setHCaptchaResponse(token);
    }

    // form sending result
    const [result, setResult] = useState('')

    // btn submitMessage
    const sendEmail = async (e) => {
        e.preventDefault();
        // vaidate phone num first
        if (isPossiblePhoneNumber(phoneNum) === false) {
            setValidNum(false)
        } else {

            setIsOpen(true)
            setResult('Sending...')
            const formData = new FormData(e.target)
            formData.append('access_key', '46ba8e33-540a-4f7f-94e1-615334a34e7a')

            formData.append('h-captcha-response', hCaptchaResponse);

            const response = await fetch("https://api.web3forms.com/submit", {
                method: 'POST',
                body: formData
            });

            const data = await response.json()

            if (data.success) {
                setResult('Message Sent');
                e.target.reset();
                setPhoneNum('')
                setHCaptchaResponse('')
                setHCaptchaKey(prevkey => prevkey + 1)

            } else {
                console.log('error', data);
                setResult(data.message)
            }
        }

    }

    // modal close func & state
    const [isOpen, setIsOpen] = useState(false)

    const closeModal = () => {
        setIsOpen(false)
    }

    return (
        <main className='contact'>
            <header className='contact__header'>
                <div className='contact__header-wrapper'>
                    <h1 className='contact__header-title'>Let's Chat</h1>
                    <h2 className='contact__header-text'>Connect with us: Ask away!</h2>
                </div>
            </header>

            <section className='contact__form' id='contact'>
                <div className='contact__form-wrapper'>
                    {/* start of form */}
                    <form onSubmit={sendEmail} className='contact__form-form'>
                        {/* avoid scam email using formsubmit honey */}
                        <input type="checkbox" name="botcheck" className="hidden" style={{ display: 'none' }} />

                        <div className='contact__form-left'>
                            <h3 className='contact__form-title'>Contact us</h3>
                            <div className='contact__form-block'>
                                {/* first name */}
                                <Input
                                    name={'firstName'}
                                    type={'text'}
                                    placeholder={'First Name'}
                                />
                                {/* last name */}
                                <Input
                                    name={'lastName'}
                                    type={'text'}
                                    placeholder={'Last Name'}
                                />
                            </div>

                            <div className='contact__form-block'>
                                {/* email */}
                                <Input
                                    name={'email'}
                                    type={'email'}
                                    placeholder={'Email'}
                                />
                                {/* phone */}
                                <div className='contact__form-phone'>
                                    <PhoneInput
                                        className='contact__form-phoneinput'
                                        defaultCountry="US"
                                        name={'phone'}
                                        type={'tel'}
                                        value={phoneNum}
                                        placeholder={'Phone Number'}
                                        onChange={phoneHandle}
                                        required
                                    />
                                    {/* phone  num error handling */}
                                    <div className={validNum ? 'contact__form-error contact__form-error-hidden' :
                                        'contact__form-error '}>
                                        <img className='contact__form-error-icon' src={dont} alt='exclamation icon' />
                                        <span className='contact__form-error-msg'>Please enter a valid number.</span>
                                    </div>
                                </div>
                            </div>

                            {/* preferred contact */}
                            <div className='contact__form-options'>
                                <h5 className='contact__form-subtitle'>I prefer to be contacted via:</h5>
                                <div className='contact__form-radios'>
                                    <label className='contact__form-label'>
                                        <Input
                                            type={"radio"}
                                            name={"contact"}
                                            value={"email"}
                                        />
                                        Email
                                    </label>
                                    <label className='contact__form-label'>
                                        <Input
                                            type="radio"
                                            name="contact"
                                            value="text"
                                        />
                                        Text
                                    </label>
                                    <label className='contact__form-label'>
                                        <Input
                                            type="radio"
                                            name="contact"
                                            value="call"
                                        />
                                        Call
                                    </label>
                                </div>
                            </div>
                            {/* message */}
                            <textarea
                                className='contact__form-message'
                                name='message'
                                placeholder={'Feel free to share your question or message here...'}
                                required
                            />
                            {/* captcha */}
                            <HCaptcha
                                key={hCaptchaKey}
                                sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
                                reCaptchaCompat={false}
                                onVerify={onHCaptchaChange}
                                size='compact'
                            />
                            <Button name={"contact__form-btn"} type={'submit'} value={'Send'} />
                        </div>
                        <div className='contact__form-right'></div>
                    </form>
                </div>

                <div className={isOpen ? 'contact__form-result' : 'contact__form-result-close'}>
                    {result === 'Sending...' && (
                        <div className='contact__form-result-modal'>
                            {/* <img className='contact__form-result-icon-close' src={close} onClick={closeModal} alt="close pages icon" /> */}
                            <img className='contact__form-result-icon-sm' src={loading} alt="letter icon" />
                            <h4 className='contact__form-result-msg'>{result}</h4>
                            <Button name={'contact__form-btn contact__form-btn-modal'} value={'Close'} btnfunc={closeModal} />
                        </div>
                    )}
                    {result === 'Message Sent' && (
                        <div className='contact__form-result-modal'>
                            {/* <img className='contact__form-result-icon-close' src={close} onClick={closeModal} alt="close pages icon" /> */}
                            <img className='contact__form-result-icon' src={letter} alt="letter icon" />
                            <h4 className='contact__form-result-msg'>{result}</h4>
                            <p className='contact__form-result-sub'>Thank you for reaching out! We'll get back to you as soon as possible.</p>
                            <Button name={'contact__form-btn contact__form-btn-modal'} value={'Close'} btnfunc={closeModal} />
                        </div>
                    )}
                    {result !== 'Sending...' && result !== 'Message Sent' && (
                        <div className='contact__form-result-modal'>
                            {/* <img className='contact__form-result-icon-close' src={close} onClick={closeModal} alt="close pages icon" /> */}
                            <img className='contact__form-result-icon' src={failed} alt="close icon" />
                            <h4 className='contact__form-result-msg'>Massage Failed</h4>
                            <p className='contact__form-result-sub'>Sorry, there was an error ({result}) sending your message. Please try again later.</p>
                            <Button name={'contact__form-btn contact__form-btn-modal'} value={'Close'} btnfunc={closeModal} />
                        </div>
                    )}


                </div>

            </section >

        </main >
    )
}

export default Contact