import Button from '../../components/Button/Button'
import Reviews from '../../components/Reviews/Reviews'
import { useNavigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

import './Home.scss'

const Home = () => {

    const navigate = useNavigate()

    const pageJump = (value) => {
        navigate(value === '' ? '/' : `/${value.toLowerCase()}`);
        window.scrollTo(0, 0)
    }


    return (
        <main className='home'>
            <header className='home__header'>
                <h1 className='home__title home__title-one'>Experience</h1>
                <h1 className='home__title home__title-two'> China</h1>
                <h1 className='home__title home__title-three'>Authentically</h1>
                <Button name={'home__btn'} value={'Book'} btnfunc={() => pageJump('trip')} />
            </header>

            <section className='home__main'>
                <section className='home__sec'>
                    <h2 className='home__sec-title'>Upcoming Trip</h2>
                    <p className='home__sec-text'>Join us to eat like the locals, discover hidden gems beyond TripAdvisor's radar, and soak up the rich art and history of ancient and modern China.</p>
                    <h6 className='home__sec-date'>Sept 12, 2024 - Sept 18, 2024</h6>
                    <Button name="home__sec-btn" value={'View Trip'} btnfunc={() => pageJump('trip')} />
                </section>

                <section className='home__slide'>
                    <div className='home__slide-img home__slide-img1'>
                        <HashLink className='home__slide-hashlink' smooth to={'/about#about-food'}>
                            <h4 className="home__slide-caption">Taste Traditional Cusine</h4></HashLink>
                    </div>


                    <div className='home__slide-img home__slide-img2'>
                        <HashLink className='home__slide-hashlink' smooth to={'/trip#trip-itinerary'}>
                            <h4 className="home__slide-caption">Unveil Ancient History</h4>
                        </HashLink>
                    </div>

                    <div className='home__slide-img home__slide-img3'>
                        <HashLink className='home__slide-hashlink' smooth to={'/about#about-food'}>
                            <h4 className="home__slide-caption">Discover New Recipes</h4>
                        </HashLink>
                    </div>

                    <div className='home__slide-img home__slide-img4'>
                        <HashLink className='home__slide-hashlink' smooth to={'/trip#trip-itinerary'}>
                            <h4 className="home__slide-caption">Embrace the Adventure</h4>
                        </HashLink>
                    </div>
                </section>

                <section className='home__reviews'>
                    <Reviews />
                </section>

                <section className="home__about">
                    <div className="home__about-wrapper">
                        <h2 className='home__sec-title home__about-title' >How It Started?</h2>
                        <p className='home__sec-text home__about-text'>Hi, I'm Lin Lin, the founder of TraveLinLin, a China touring company. By day, I work as a Chinese American flight attendant, fueled by a passion for exploring the globe. Over the past decade, I've ventured through 40 countries, yet I'm always drawn back to my motherland, China.</p>
                        <p className='home__sec-text home__about-text-bold'>I want to reveal China's breathtaking beauty, its quirks, and its endless excitement...</p>
                        <Button name="home__sec-btn home__about-btn" value={'Read More'} btnfunc={() => pageJump('about')} />
                    </div>

                    <section className='home__slide home__about-slide'>
                        {/* <div className='home__about-img home__about-img1'></div> */}
                        <div className='home__about-img home__about-img2'></div>
                        {/* <div className='home__about-img home__about-img3'></div> */}
                    </section>

                </section>

            </section >
        </main>
    )
}

export default Home