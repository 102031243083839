import './Nav.scss'
import Button from '../Button/Button'
import burger from '../../assets/icons/burger.png'
import exit from '../../assets/icons/x.png'

// core stuff
import { useNavigate, NavLink } from 'react-router-dom'
import { useState, useEffect } from 'react'

const Nav = () => {

    // sticky on top navbar
    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // Set the threshold for when the navbar becomes sticky

            let threshold;

            if (window.innerWidth < 768) {
                threshold = 400
            } else if (window.innerWidth >= 768 && window.innerWidth <= 1153) {
                threshold = 600
            } else {
                threshold = 700
            }

            setIsSticky(scrollPosition >= threshold);
        };

        // Add scroll event listener when component mounts
        window.addEventListener('scroll', handleScroll);

        // Remove scroll event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    const navigate = useNavigate()

    const pageJump = (value) => {
        navigate(value === '' ? '/' : `/${value.toLowerCase()}`);
        window.scrollTo(0, 0)
        setNavOpen(false)
    }

    // hamburger 
    const [navOpen, setNavOpen] = useState(false)

    const openBurger = () => {
        console.log('click')
        setNavOpen(true)
    }

    const closeBurger = () => {
        console.log('click')
        setNavOpen(false)
    }

    return (
        <>
            <nav className={isSticky ? 'nav nav-stick' : 'nav nav-reg'}>
                <div className='nav__left'>
                    <Button name={'nav__btn-logo'} value={'TraveLinLin'} btnfunc={() => pageJump('')} />
                </div>

                <div className='nav__right'>
                    <NavLink exact to="/" activeClassName="active" className='nav__btn'>Home</NavLink>
                    <NavLink to="/about" activeClassName="active" className='nav__btn'>About</NavLink>
                    <NavLink to="/trip" activeClassName="active" className='nav__btn'>Trip</NavLink>
                    <NavLink to="/faq" activeClassName="active" className='nav__btn'>FAQ</NavLink>
                    <NavLink to="/contact" activeClassName="active" className='nav__btn'>Contact</NavLink>
                </div>

                <div className='nav__responsive'>
                    <img className='nav__responsive-icon' src={burger} alt='burger icon' onClick={openBurger} />
                </div>

                <div className={navOpen ? 'nav__responsive-overlay' : 'hidden'}>
                    <div className={navOpen ? 'nav__responsive-navbar' : 'hidden'}>
                        <div className='nav__responsive-exit-wrapper'><img className='nav__responsive-exit' src={exit} alt='exit icon' onClick={closeBurger} /></div>
                        <div className='nav__responsive-list'>
                            <NavLink exact to="/" activeClassName="active" className='nav__btn nav__responsive-btn' onClick={closeBurger}>Home</NavLink>
                            <NavLink to="/about" activeClassName="active" className='nav__btn nav__responsive-btn' onClick={closeBurger}>About</NavLink>
                            <NavLink to="/trip" activeClassName="active" className='nav__btn nav__responsive-btn' onClick={closeBurger}>Trip</NavLink>
                            <NavLink to="/faq" activeClassName="active" className='nav__btn nav__responsive-btn' onClick={closeBurger}>FAQ</NavLink>
                            <NavLink to="/contact" activeClassName="active" className='nav__btn nav__responsive-btn' onClick={closeBurger}>Contact</NavLink>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )

}

export default Nav