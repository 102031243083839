import './Button.scss'


const Button = ({ name, value, btnfunc }) => {

    return (
        <button className={name} onClick={btnfunc}>{value}</button>

    )
}

export default Button

