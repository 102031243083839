import './Reviews.scss'
import { useState, useEffect } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import NextBtn from '../NextBtn/NextBtn';
import PrevBtn from '../PrevBtn/PrevBtn';
import Card from '../Card/Card';

const Reviews = () => {

    // store all reviews 
    const [reviews, setReviews] = useState([])

    // fetch all reviews from api
    useEffect(() => {
        const fetchReview = async () => {
            try {
                const res = await fetch("https://reviews.lin2013lin.workers.dev/");
                if (!res.ok) {
                    console.log('Network response issue')
                }
                const result = await res.json()
                setReviews(result)

            } catch (error) {
                console.log(error)
            }
        }

        fetchReview()
    }, [])

    const cards = (data) => {
        return (
            // <div key={data.id} ref={addToRefs}>
            <Card
                key={data.id}
                avatar={data.image}
                name={data.name}
                review={data.content}
            />
            /* </div> */
        )
    }

    // const [sliderHeight, setSliderHeight] = useState('auto')
    // const slidesRef = useRef([]);

    // slider settings
    const settings = {
        dots: true,
        fade: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextBtn icon={ArrowForwardIos} />,
        prevArrow: <PrevBtn icon={ArrowBackIos} />,
        appendDots: (dots) => <ul>{dots}</ul>,
        customPaging: (i) => (
            <div className="ft-slick__dots--custom">
                <div className="loading" />
            </div>
        ),
        adaptiveHeight: true,
        // beforeChange: (oldIndex, newIndex) => {
        //     if (slidesRef.current[newIndex]) {
        //         setSliderHeight(slidesRef.current[newIndex].offsetHeight);
        //     }
        // }
    };
    // useEffect(() => {
    //     if (slidesRef.current.length > 0) {
    //         setSliderHeight(slidesRef.current[0].offsetHeight);
    //     }
    //     // eslint-disable-next-line
    // }, []);

    // const addToRefs = (el) => {
    //     if (el && !slidesRef.current.includes(el)) {
    //         slidesRef.current.push(el);
    //     }
    // };


    return (
        <main className='reviews'>
            <h1 className='reviews__title'>Echoes from Our Past Travelers</h1>
            <Slider {...settings}>{reviews.map(cards)}</Slider>
        </main>
    )

}

export default Reviews  