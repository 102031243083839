import './About.scss'
import Button from '../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import Carousel from '../../components/Carousel/Carousel'

const About = () => {
    const navigate = useNavigate()

    const pageJump = (value) => {
        navigate(value === '' ? '/' : `/${value.toLowerCase()}`);
        window.scrollTo(0, 0)
    }

    return (
        <main className='about'>
            <header className='about__header'>
                <h2 className='about__title'>Discover
                    <span className='about__title-1'> Through</span>
                    <span className='about__title-2'>Local Lens</span> </h2>
            </header>

            <section className='about__main'>
                <section className='about__sec'>
                    <div className='about__sec-left'> </div>
                    <div className='about__sec-right'>
                        {/* <span className='about__sec-pretext'>Meet Lin Lin</span> */}
                        <h3 className='about__sec-subtitle'>How it all started...</h3>
                        <p className='about__sec-text'>Hi, I'm Lin Lin, the founder of TraveLinLin, a China touring company. By day, I work as a Chinese American flight attendant, fueled by a passion for exploring the globe. Over the past decade, I've ventured through 40 countries, yet I always drawn back to my motherland, China.</p>
                        <p className='about__sec-text'>I grew up in NYC, but I spent many years living and working in and out of China. Throughout the years, I had the pleasure of guiding numerous groups of friends around my homeland.</p>
                        <p className='about__sec-text'>While many are eager to explore China, cultural nuances and language barriers can be daunting. However, with my linguistic and cultural insights, visiting China was a life-changing journey for many of my friends. </p>
                        <p className='about__sec-text'>This is the driving force behind TraveLinLin: to unveil the real China through the lens of a local, not just the typical tourist attractions.</p>
                        <p className='about__sec-text'>I want to reveal China's breathtaking beauty, its quirks, and its endless excitement.</p>
                    </div>
                </section>

                <section className='about__two'>
                    <div className='about__two-left'></div>
                    <div className='about__two-mid'>
                        <h4 className='about__two-caption'>Following detailed research & planning...</h4>
                        <p className='about__two-text'>I've proudly led two full groups of clients on exclusive trips to Beijing and Xi'an prior to 2020. Now, with China reopening its doors to the world, I'm thrilled to announce our upcoming week long Beijing trip on September 12, 2024.</p>
                        <p className='about__two-text'>Join us to eat like the locals, discover hidden gems beyond TripAdvisor's radar, and soak up the rich art and history of ancient and modern China. </p>
                        <p className='about__two-text'>Keeping our groups small ensures an authentic, intimate experience for everyone. Space is limited, so book now. I can't wait to welcome you all in September!</p>
                        <div className='about__two-btn-wrapper'>
                            <Button name="about__two-btn" value={'Book'} btnfunc={() => pageJump('trip')} />
                        </div>
                    </div>
                    <div className='about__two-right'></div>
                </section>

                <section className='about__carousel' id='about-food'>
                    <h3 className='about__carousel-title'>Cultural Kaleidoscope</h3>
                    {/* <h4 className='about__carousel-subtitle'>Dive Deeper, Immerse Yourself</h4> */}
                    <div className='about__carousel-content'>
                        <div className='about__carousel-description'>
                            {/* <h3 className='about__carousel-title'>Cultural Kaleidoscope</h3> */}
                            <h4 className='about__carousel-subtitle'>Dive Deeper, Immerse Yourself</h4>

                            <p className='about__carousel-text'>Get ready to embark on an authentic experience with TraveLinLin.
                                Our mission is to ensure that everyone experiences China in its entirety, not just as an outsider visiting.
                            </p>
                            <p className='about__carousel-text'>
                                This journey begins with savoring authentic Chinese flavors and mastering the art of cooking traditional dishes.
                            </p>
                            <p className='about__carousel-text'>
                                Beyond cooking classes, our past tours also featured opportunities for participants to immerse themselves in the authentic charm of traditional tea ceremonies,
                                as well as experiencing the essence of local life by learning and practicing Tai Chi in neighborhood parks, just like the locals.
                            </p>

                        </div>

                        <div className='about__carousel-swiper'>
                            <Carousel />
                        </div>
                    </div>
                </section>
            </section>

        </main>
    )
}

export default About